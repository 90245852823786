import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '@core/services/local/main.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private router: Router,
    private mainService: MainService,
  ) {
    this.mainService.isLogedIn.subscribe(res => {
      if (res === false) {
        this.router.navigate(['/auth']);
      }
    })
  }
}
