import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbModel } from '@core/models/local/breadcrumb.model';
import { BreadcrumbService } from '@core/services/local/breadcrumb.service';

@Component({
  selector: 'core-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {

  constructor(
    private router: Router,
    public breadcrumbService: BreadcrumbService
  ) { }

  onClickBreadcrumb(event: MouseEvent, { navigateRoute }: BreadcrumbModel): void {
    if (!navigateRoute) {
      return;
    }

    if (event.ctrlKey || event.metaKey) {
      const url = this.router.createUrlTree([navigateRoute]);
      window.open(url.toString(), '_blank');
    } else {
      this.router.navigate([navigateRoute]);
    }
  }
}
