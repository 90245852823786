import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'shared-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {

  @Input() defaultIcon: string = 'select_all';

  @Input() color: string; // 'white' | 'primary';

  @Input() class = '';

  @Input() size: number = 24;

  @Input() hover = false;

  @Output() tap = new EventEmitter();

  icon: any;

  @Input()
  set name(name: string) {
    this.icon = name;
  }
}
