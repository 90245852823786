import { Injectable } from '@angular/core';

@Injectable({ providedIn: "root" })
export class RequestQueueService {

  queue: string[] = []
  public pushLoaderQueue(url: string) {
    this.queue.push(url)
  }

  public popLoaderQueue(url: string) {
    const foundIndex = this.queue.findIndex(i => i === url)
    if (foundIndex !== -1) {
      this.queue.splice(foundIndex, 1);
    }
  }
}