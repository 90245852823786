import { Injectable } from '@angular/core';
import { DialogComponent } from '@core/components/dialog/dialog.component';
import { DialogModel } from '@core/models/local/dialog.model';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private bsModalService: BsModalService
  ) {
  }

  show(options: DialogModel) {
    this.bsModalService.show(DialogComponent, { initialState: { options } });
  }

  error(options: DialogModel) {
    if (!options.title) {
      options.title = 'Ошибка';
    }

    this.bsModalService.show(DialogComponent, { initialState: { options } });
  }
}
