import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SKIP_ERROR_HANDLING_PARAMS } from '@core/utils/url.utils';
import { ApiAbstract } from '@core/abstract/api.abstract';
import { AuthLoginRequestModel, AuthLoginResponseModel } from '@core/models/auth/token.model';
import { ProfileModel } from '@core/models/auth/profile.model';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IdentityService extends ApiAbstract {

  profile: ProfileModel;
  profile_list: ProfileModel[];

  constructor(
    protected http: HttpClient,
  ) {
    super('identity');
  }

  login(payload: AuthLoginRequestModel): Observable<AuthLoginResponseModel> {
    return this.http.post<AuthLoginResponseModel>(this.getUrl('auth/token'), payload);
  }

  refreshToken(refresh: string): Observable<AuthLoginResponseModel> {
    return this.http.post<AuthLoginResponseModel>(
      this.getUrl('auth/token/refresh'), { refresh }, { params: SKIP_ERROR_HANDLING_PARAMS }
    );
  }

  logout(): Observable<any> {
    return this.http.post<AuthLoginResponseModel>(
      this.getUrl('auth/logout'), null, { params: SKIP_ERROR_HANDLING_PARAMS }
    );
  }

  getCurrUserProfile(): Observable<ProfileModel> {
    return this.http.get<ProfileModel>(this.getUrl('profile')).pipe(tap(profile => this.profile = profile));
  }

  getProfile(id: number | string): Observable<ProfileModel> {
    return this.http.get<ProfileModel>(this.getUrl(`profiles/${id}`)).pipe(tap(profile => this.profile = profile));
  }

  getProfiles(params?: any): Observable<ProfileModel[]> {
    return this.http.get<ProfileModel[]>(this.getUrl('profiles'), { params }).pipe(tap(profiles => this.profile_list = profiles));
  }
}
