import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable()
export class RedirectGuard implements CanActivate {

  constructor(
    private router: Router,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = JSON.parse(localStorage.getItem('auth'))
    if (token) {
      console.log('[RedirectGuard] Has token, redirecting app.');
      this.router.navigate(['/']);
      return false;
    }

    console.log('[RedirectGuard] No token, entering auth.');
    return true;
  }
}
