import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { RequestQueueService } from '@core/services/local/request_queue.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(
    private requestQueueService: RequestQueueService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.params.has('skip_loader_showing')) {
      return next.handle(req);
    }

    this.requestQueueService.pushLoaderQueue(req.url);

    return next.handle(req)
      .pipe(
        finalize(() => {
          this.requestQueueService.popLoaderQueue(req.url);
        })
      );
  }
}
