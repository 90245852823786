import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigatorComponent } from '@core/components/navigator/navigator.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { RedirectGuard } from '@core/guards/redirect.guard';

const routes: Routes = [
  {
    path: '',
    component: NavigatorComponent,
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./feature/auth/auth.module').then(m => m.AuthModule),
    canActivate: [RedirectGuard],
  },
  {
    path: 'highschool-admin',
    loadChildren: () => import('./feature/highschool-admin/highschool-admin.module').then(m => m.HighschoolAdminModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
