<div class="modal-header">{{options.title}}</div>

<div class="modal-body" [innerHTML]="options.message"></div>

<div class="modal-footer justify-content-end">

  <button *ngIf="options.cancelText" class="btn btn-outline-secondary mx-2"
    (click)="onClose()">{{options.cancelText}}</button>

  <button class="btn btn-primary" (click)="onConfirm()">{{options.confirmText}}</button>

</div>