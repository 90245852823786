export type GroupType = 'highschool-admin'

export const SYSTEM_GROUPS: GroupType[] = ['highschool-admin'];

export const GROUP_LABEL_MAP = {
  'highschool-admin': 'Админ'
};

export const GROUP_ROUTE_MAP = {
  'highschool-admin': '/highschool-admin'
}
