import { Injectable } from '@angular/core';
import { AuthLoginResponseModel } from '@core/models/auth/token.model';
import { LocalStorageService } from './storage/localStorage.service';
import * as moment from 'moment';
import { HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  access: string = "";
  refresh: string = "";

  constructor(
    private storage: LocalStorageService
  ) {
    this.storage
      .getItem<AuthLoginResponseModel>('auth')
      .subscribe((token) => {
        this.access = token.access || undefined
        this.refresh = token.refresh || undefined
      })
  }

  // getters
  public get decodedAccessToken(): any {
    return JSON.parse(window.atob(this.access.split('.')[1]));
  }

  public get accessTokenExpireDate(): moment.Moment {
    return moment.unix(this.decodedAccessToken.exp)
  }

  public get leftSeconds(): number {
    return moment().diff(this.accessTokenExpireDate, 'seconds');
  }

  public isTokenExpired(request: HttpRequest<any>): boolean {
    if (request.params.has('skip_error_handling')) {
      return false;
    }
    if (!this.access || !this.accessTokenExpireDate) {
      return false;
    }
    return this.leftSeconds > -30;
  }

  // functions
  public setToken(tokenObj: AuthLoginResponseModel) {
    this.access = tokenObj.access
    this.refresh = tokenObj.refresh
    this.storage.setItem<AuthLoginResponseModel>('auth', tokenObj)
  }

  public removeToken() {
    this.storage.getItem<AuthLoginResponseModel>('auth').next(null)
  }
}