const jooApiBaseUrl = 'https://app.dev.joo.kz';
const jooApiBaseUrlV1 = `${jooApiBaseUrl}/api/v1`;
const jooApiBaseUrlV2 = `${jooApiBaseUrl}/api/v2`;

export const environment = {
  production: false,
  jooApiBaseUrl,
  jooApiBaseUrlV1,
  jooApiBaseUrlV2,
};
