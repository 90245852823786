import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { extractHttpErrorMessage } from '@core/utils/url.utils';
import { DialogService } from '@core/services/local/dialog.service';
import { CustomHttpParamEncoder } from '@core/utils/custom-codec';
import { MainService } from '@core/services/local/main.service';
import { TokenService } from '@core/services/local/token.service';
import { IdentityService } from '@core/services/api/auth.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  refreshTokenInProgress = false;

  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    private mainService: MainService,
    private tokenService: TokenService,
    private identityService: IdentityService,
    private dialogService: DialogService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    request = this.addAuthHeader(request);

    request = this.changeParamsEncoding(request)

    if (this.tokenService.isTokenExpired(request)) {
      return this.refreshToken().pipe(
        switchMap(() => {
          request = this.addAuthHeader(request);
          return next.handle(request);
        }),
        catchError(e => {
          if (e.status !== 401) {
            return this.handleResponseError(e);
          }
          this.tokenService.removeToken()
          this.mainService.navigateAuth()
          return throwError(e);
        }));
    }

    return next.handle(request).pipe(
      catchError(error => {
        return this.handleResponseError(error, request, next);
      })
    );
  }

  changeParamsEncoding(request: HttpRequest<any>) {
    const params = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromString: request.params.toString(),
    });

    return request.clone({
      params
    })
  }

  addAuthHeader(request: HttpRequest<any>) {
    const token = this.tokenService
    if (request.params.has('media_head_req')) {
      return request
    }
    request = request.clone({
      setHeaders: {
        'Accept-Language': 'ru'
      }
    });

    if (token.access && !request.url.includes('https://storage-joo-kz.object.pscloud.io')) {
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token.access}`
        }
      });
    }

    return request;
  }

  handleResponseError(error, request?, next?) {
    if (request.params.has('ц')) {
      return throwError(error);
    }

    if (error.status === 401) {
      return this.refreshToken().pipe(
        switchMap(() => {
          request = this.addAuthHeader(request);
          return next.handle(request);
        }),
        catchError(e => {
          if (e.status !== 401) {
            return this.handleResponseError(e);
          }
          this.tokenService.removeToken()
          this.mainService.navigateAuth()
          return throwError(e);
        }));
    }

    let message = '';

    if (error.status >= 500) {
      message = 'Ошибка сервера';
    } else if (error.status === 404) {
      message = 'Не найдено';
    } else {
      message = extractHttpErrorMessage(error.error);
    }

    this.dialogService.error({ message });

    return throwError(error);
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.identityService.refreshToken(this.tokenService.refresh).pipe(
        map(token => {
          this.tokenService.setToken(token)
          return token;
        }),
        tap(() => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
        }),
        catchError(error => {
          this.refreshTokenInProgress = false;
          return throwError(error);
        }));
    }
  }
}
