<div class="wrapper" *ngIf="breadcrumbService.items as breadcrumbs">

  <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">

    <a class="nav-item" [class.last]="last" (click)="onClickBreadcrumb($event, breadcrumb)">
      {{breadcrumb.title}}
    </a>

    <div *ngIf="!last" class="separator">/</div>

  </ng-container>

</div>