<div class="wrapper">

  <div class="fixed-wrapper">

    <nav>

      <div class="d-flex align-items-center">

        <img class="logo" src="./assets/img/icon.png" (click)="onNavigateHome()" />

      </div>

      <div></div>
      <div></div>

      <button class="btn btn-danger" *ngIf="mainService.isLogedIn | async" (click)="onLogout()">
        Выйти
      </button>

    </nav>

    <core-breadcrumbs></core-breadcrumbs>

  </div>

</div>