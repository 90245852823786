import { Component } from '@angular/core';
import { MainService } from '@core/services/local/main.service';

@Component({
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent {

  constructor(
    private mainService: MainService
  ) {
    this.mainService.navigateHome()
  }
}
