import { environment } from '@env';


export abstract class ApiAbstract {

  constructor(
    protected uri: string
  ) { }

  protected getUrl(branch?: string | number): string {
    let url = `${environment.jooApiBaseUrlV1}/${this.uri}`;
    if (branch) url += `/${branch}`;
    return `${url}/`;
  }

  protected getUrlV2(branch?: string | number): string {
    let url = `${environment.jooApiBaseUrlV2}/${this.uri}`;
    if (branch) url += `/${branch}`;
    return `${url}/`;
  }

  protected getHighschoolUrl(branch?: string | number): string {
    let url = `${environment.jooApiBaseUrlV1}/highschool/sms/${this.uri}`;
    if (branch) url += `/${branch}`;
    return `${url}/`;
  }
}
