import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { IdentityService } from '@core/services/api/auth.service';
import { ConstantsService } from '@core/services/api/constants.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private constansService: ConstantsService,
    private identityService: IdentityService,
    private router: Router,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = JSON.parse(localStorage.getItem('auth'))
    if (token) {
      this.identityService.getCurrUserProfile().toPromise().then()
      this.constansService.fetchCached().toPromise().then()

      console.log('[AuthGuard] Has token, entering app.');
      return true;
    }

    console.log('[AuthGuard] No token, redirecting auth.');
    this.router.navigate(['/auth']);
    return false;
  }
}
