import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IdentityService } from '@core/services/api/auth.service';
import { MainService } from '@core/services/local/main.service';

@Component({
  selector: 'core-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    public router: Router,
    public mainService: MainService,
    private identityService: IdentityService
  ) { }

  ngOnInit(): void {
  }

  onNavigateHome(): void {
    this.mainService
  }

  onLogout(): void {
    this.identityService.logout()
      .toPromise()
      .finally(() => {
        this.mainService.storage.removeItem('auth')
      });
  }
}
