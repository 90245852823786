import { Component, OnInit } from '@angular/core';
import { DialogModel } from '@core/models/local/dialog.model';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  options: DialogModel;

  constructor(
    private bsModalRef: BsModalRef,
  ) {
  }

  ngOnInit(): void {
    if (!this.options.confirmText) this.options.confirmText = 'OK';
  }

  onClose() {
    this.bsModalRef.hide();
  }

  onConfirm(): void {
    this.onClose();

    if (this.options.onConfirm) {
      this.options.onConfirm();
    }
  }
}
