import { Injectable } from '@angular/core';
import { BreadcrumbModel } from '@core/models/local/breadcrumb.model';

@Injectable({ providedIn: "root" })
export class BreadcrumbService {
  items: BreadcrumbModel[];

  updateBreadcrumbs(items: BreadcrumbModel[]) {
    this.items = items
  }
}