import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ApiAbstract } from '@core/abstract/api.abstract';
import { ConstantsModel } from '@core/models/const/constant.model';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ConstantsService extends ApiAbstract {

  constants: ConstantsModel;

  constructor(
    protected http: HttpClient
  ) {
    super('constants');
  }

  fetch(): Observable<ConstantsModel> {
    if (this.constants) return of(this.constants);
    return this.http.get<ConstantsModel>(this.getHighschoolUrl()).pipe(map(constants => {
      this.constants = constants
      return constants
    }));
  }

  fetchCached(): Observable<ConstantsModel> {
    if (this.constants) return of(this.constants);
    return this.fetch();
  }
}
