import { Component } from '@angular/core';
import { RequestQueueService } from '@core/services/local/request_queue.service';

@Component({
  selector: 'core-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  constructor(
    public loaderService: RequestQueueService
  ) { }

  get showLoader(): boolean {
    return this.loaderService.queue.length > 0
  }
}
