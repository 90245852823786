import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthLoginResponseModel } from '@core/models/auth/token.model';
import { GroupType, GROUP_ROUTE_MAP } from '@core/types/group.type';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LocalStorageService } from './storage/localStorage.service';

@Injectable({ providedIn: "root" })
export class MainService {

  role: GroupType;

  constructor(
    private router: Router,
    public storage: LocalStorageService,
  ) { }

  // getters
  public get isLogedIn(): Observable<boolean> {
    return this.storage
      .getItem<AuthLoginResponseModel>('auth')
      .pipe(switchMap((obj) => {
        return of(obj !== null && obj !== undefined)
      }))
  }

  public get isHighschoolAdmin(): boolean {
    return this.role === 'highschool-admin';
  }

  // functions
  public navigateHome() {
    this.router.navigate([GROUP_ROUTE_MAP[this.role] || "highschool-admin"])
  }

  public navigateAuth() {
    this.router.navigate(['/auth'])
  }
}